// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import SettingsMenu from "./SettingsMenu";
import NotificationIcon from "./NotificationIcon";
import Web3 from "web3";

import useStyles from "./styles";
import SwitchUserPopUp from "../../pages/Users/SwitchUserPopup";
import { useDispatch, useSelector } from "react-redux";
import CeroButton from "../CeroButton";
import {
  resetAuthentication,
  switchBackToSuperAdmin,
  walletAddress,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../services/cookie";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";
// import { useSwitchNetwork, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react';

const Header = ({ placeholder, onChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [heading, setHeading] = useState(null);
  const showSwitchUserModal = useSelector(
    (state) => state.users.showSwitchUserModal
  );
  const role = getCookie("role");
//walletAddress
useSelector(
  (state) => state.users.walletAddress
);
  const userId = useSelector((state) => state.auth.userInfo?.id);
  const wallet = useSelector(
    (state) => state.users.walletAddress
  );

  const isProxyLogin = useSelector(
    (state) => state.auth.userInfo?.is_proxy_logged_by_super_admin
  );
  const [account, setAccount] = useState(null);

  useEffect(() => {
    if (window.location.pathname.includes("/dashboard-config")) {
      setHeading("Configurable Dashboard");
    }
    else if (window.location.pathname.includes("/dashboard")) {
      setHeading("Dashboard");
    } else if (window.location.pathname.includes("/goals")) {
      setHeading("Goals");
    } else if (window.location.pathname.includes("/facilities")) {
      setHeading("Facilities");
    } else if (window.location.pathname.includes("/users")) {
      setHeading("User Management");
    } else if (window.location.pathname.includes("/approval-status")) {
      setHeading("Approval Status");
    } else if (window.location.pathname.includes("/tickets")) {
      setHeading("Internal Tickets");
    } else if (window.location.pathname.includes("/assesment-cycles")) {
      setHeading("Assessment Cycles");
    } else if (window.location.pathname.includes("/reports")) {
      setHeading("Reports");
    } else if (window.location.pathname.includes("/audit-status")) {
      setHeading("Audit Status");
    } else if (window.location.pathname.includes("/auditor-management")) {
      setHeading("Auditor Management");
    } else if (window.location.pathname.includes("/audit-type-management")) {
      setHeading("Audit Management");
    } else if (
      window.location.pathname.includes("/emissions/stationary_combustion")
    ) {
      setHeading("Topics");
    } else if (window.location.pathname.includes("/profile")) {
      setHeading("My Profile");
    } else if (window.location.pathname.includes("/companies")) {
      setHeading("Companies");
    } else if (window.location.pathname.includes("/superadmin-users")) {
      setHeading("Users");
    } else if (window.location.pathname.includes("/settings")) {
      setHeading("My Profile");
    } else if (window.location.pathname.includes("/emissions")) {
      setHeading("Topics");
    } else if (window.location.pathname.includes("/read-only-users")) {
      setHeading("Read Only Users");
    } else if (window.location.pathname.includes("/request-approvals")) {
      setHeading("Request Approvals");
    } else if (window.location.pathname.includes("/pending-approvals")) {
      setHeading("Pending Approvals");
    } else if (window.location.pathname.includes("/pending-approval-details")) {
      setHeading("Pending Approvals");
    } else if (window.location.pathname.includes("/glossary")) {
      setHeading("Glossary");
    } else if (window.location.pathname.includes("/subscription-details")) {
      setHeading("Subscription Info");
    } else if (window.location.pathname.includes("/carbon-credit-pool")) {
      setHeading("Carbon Credit Pool");
    } else if (window.location.pathname.includes("/vision-mission")) {
      setHeading("Vision & Mission");
    } else if (window.location.pathname.includes("/initiatives")) {
      setHeading("Initiatives");
    } else if (window.location.pathname.includes("/initiative-tracking")) {
      setHeading("Initiative Tracking");
    } else if (window.location.pathname.includes("/target-vs-actuals")) {
      setHeading("Target vs Actuals");
    } else if (window.location.pathname.includes("/trade/sell")) {
      setHeading("Sell Carbon Credits");
    } else if (
      window.location.pathname.includes("/trade/carboncredits-added")
    ) {
      setHeading("Buy Carbon Credits");
    } else if (window.location.pathname.includes("/trade-profile")) {
      setHeading("My Profile");
    } else if (window.location.pathname.includes("/trade/buy")) {
      setHeading("Buy Carbon Credits");
    } else if (window.location.pathname.includes("/survey")) {
      setHeading("Survey");
    } else if (window.location.pathname.includes("/track-progress")) {
      setHeading("Track Progress");
    } else if (window.location.pathname.includes("/history")) {
      setHeading("History");
    } else if (window.location.pathname.includes("/approval-flow")) {
      setHeading("Approval Flow");
    } else if (window.location.pathname.includes("/support-tickets")) {
      setHeading("Support Tickets");
    } else if (window.location.pathname.includes("/super-admin-tickets")) {
      setHeading("Tickets");
    } else if (window.location.pathname.includes("/carbon-offset")) {
      setHeading("Carbon Offset");
    } else if (window.location.pathname.includes("/notifications")) {
      setHeading("Notifications");
    } else if (window.location.pathname.includes("/add-notifications")) {
      setHeading("Notifications");
    } else if (window.location.pathname.includes("/blockchain")) {
      setHeading("Blockchain");
    } else if (window.location.pathname.includes("/dashboard-config")) {
      setHeading("Configurable Dashboard");
    } else if (window.location.pathname.includes("/offset-approval-management")) {
      setHeading("Offset Approval Status");
    }else if (window.location.pathname.includes("/offset-approval-pending")) {
      role === rolesEnum.BUSINESS_USER ? setHeading("Offset Request Approvals") :  setHeading("Offset Pending Approvals");
    }
  });

  const handleSuperAdminLogin = () => {
    dispatch(resetAuthentication());
    dispatch(switchBackToSuperAdmin(userId));
    navigate("/dashboard");
  };

  const connectMetamask = async () => {
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        // setWeb3(web3Instance);
        // Explicitly request permissions to connect accounts
        const permissions = await window.ethereum.request({
          method: "wallet_requestPermissions",
          params: [{ eth_accounts: {} }],
        });

        if (permissions) {
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]);
          dispatch(walletAddress(accounts[0]));
        }
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      alert("Please install MetaMask to use this feature.");
    }
  };

  const disconnectMetamask = () => {
    setAccount(null);
    dispatch(walletAddress(""));
    console.log("Disconnected from MetaMask");
  };
  const handleConnection = async () => {
    if (wallet) {
      disconnectMetamask();
    } else {
      connectMetamask();
    }
  };
  return (
    <Box
      className={role === "trade_admin" ? classes.tradeHeader : classes.header}
    >
      <Box className={classes.searchBoxContainer}>
        <Typography className={classes.heading}>{heading}</Typography>
      </Box>
      {isProxyLogin && (
        <CeroButton
          variant="contained"
          onClick={handleSuperAdminLogin}
          buttonText="Back as Super Admin"
          className={classes.buttonSecondary}
        />
      )}
      <Box className={classes.notificationContainer}>
        
        {role === "super_admin" && (
          <CeroButton
            variant="contained"
            buttonText={wallet ? "Disconnet" : "Connect Wallet"}
            onClick={() => handleConnection()}
            className={classes.buttonSecondary}
          />
        )}

        <NotificationIcon />
        <SettingsMenu />
        {showSwitchUserModal && <SwitchUserPopUp isOpen={true} />}
      </Box>
    </Box>
  );
};

export default Header;